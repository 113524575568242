import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';

import gamerizeOne from '../../assets/images/gamerize/new/gamerize-1.webp';
import gamerizeTwoA from '../../assets/images/gamerize/new/gamerize-2-a.webp';
import gamerizeTwoB from '../../assets/images/gamerize/new/gamerize-2-b.webp';
import gamerizeTwoC from '../../assets/images/gamerize/new/gamerize-2-c.webp';
import gamerizeThreeA from '../../assets/images/gamerize/new/gamerize-3-a.webp';
import gamerizeThreeB from '../../assets/images/gamerize/new/gamerize-3-b.webp';
import gamerizeThreeC from '../../assets/images/gamerize/new/gamerize-3-c.webp';
import gamerizeThreeD from '../../assets/images/gamerize/new/gamerize-3-d.webp';
import gamerizeFour from '../../assets/images/gamerize/new/gamerize-4.webp';
import gamerizeFiveA from '../../assets/images/gamerize/new/gamerize-5-a.webp';
import gamerizeFiveB from '../../assets/images/gamerize/new/gamerize-5-b.webp';
import gamerizeFiveC from '../../assets/images/gamerize/new/gamerize-5-c.webp';
import gamerizeFiveD from '../../assets/images/gamerize/new/gamerize-5-d.webp';
import gamerize from '../../assets/images/gamerize/gamerize.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Gamerize extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">The Funnest Dictionary in the World</div>
                <div className="subtitle section-margin">
                  Helping young learners develop vocabulary through a gamified experience
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#034A70'}}> AAS</span>
                  <span style={{color: '#89D8E9'}}> PRESS</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build Gamerize Dictionary, a gamified language learning experience designed to
                      help young Japanese learners improve vocabulary.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed a mobile app for a series of language learning mini-games embedded
                      within a larger city-building game.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc m-0">
                      Gamerize Dictionary had over 2000 organic users, 500 daily sessions, and 10
                      check-ins on average per day.
                    </p>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/aGZ2D_c3Pfc"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://aaspress.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        AAS Press
                      </a>{' '}
                      , a publishing company based in Hyogo, Japan, wanted to build a gamified
                      learner dictionary to help young learners develop vocabulary. Adam Kardos, the
                      founder and CEO of AAS Press, had authored several books for young learners
                      including “Here Come the Unicorns” which won the{' '}
                      <a
                        className="description"
                        href="https://erfoundation.org/wordpress/awards-grants/awards/2020-lll-awards/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        2020 LLL Award
                      </a>{' '}
                      . Adam was now looking to employ a rewards-based approach in a gamified
                      experience to engross children in learning a new language.
                    </p>
                    <br />
                    <p className="description">
                      AAS Press contacted Tintash with this exciting opportunity. They shared a
                      vision document and related sketches of a series of mini-games embedded within
                      a larger city-building game. Progress in the game was meant to be the outcome
                      of improvement in language skills. The project involved building these game
                      components as well as a web-based portal that teachers and administrators
                      could use to assign language learning quests to students and track their
                      progress.
                    </p>
                    <br />
                    <p className="description">
                      Tintash submitted a proposal containing the team requirements, the process to
                      be followed, as well as the cost and time estimates. The project was our first
                      interface with the Japanese market.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12 p-0">
                      <img className="w-100  " src={gamerizeOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Our team kickstarted the development process by working on some concept art
                      including avatars, maps and buildings. It was important to get the artwork
                      aligned with the demographic and cultural inclinations of our target audience.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="image-description text-center mb-3 col-12">
                      Character Customization
                    </div>
                    <div className="col-lg-4">
                      <img
                        className="w-100 sub-pic "
                        src={gamerizeTwoA}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-4">
                      <img
                        className="w-100  sub-pic"
                        src={gamerizeTwoB}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-4">
                      <img
                        className="w-100  sub-pic"
                        src={gamerizeTwoC}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      We initially focused on the riskiest ones among the essential set of features.
                      One such key area was speech recognition-based practice learning. When the
                      team found out that the native device APIs for speech recognition were not
                      effective in the target market, we implemented the service provided by Soapbox
                      Labs in its stead.
                    </p>
                    <br />
                    <p className="description">
                      Since the objective was to launch the game pilot within six months to enable
                      usability testing, our team raced to get a basic game loop in place. We built
                      a map structure so that users could access quests through the various
                      mini-games as they watched their kingdom grow. We also designed a portal which
                      teachers could use to assign Japanese-English language pairs to students for
                      practice via quests.
                    </p>
                    <br />
                    <p className="description">
                      The team included a content database for scalability and compatibility with
                      the constraints of the Japanese language. This was in line with the client’s
                      vision of building a platform with interchangeable content and which could
                      eventually be used for any form of language learning. Once a stable and
                      testable game loop was in place, we had a pilot launch.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="image-description text-center  mb-2 col-12">
                      In-Game Screens
                    </div>
                    <div className="col-lg-6">
                      <img className="w-100 my-4 " src={gamerizeThreeA} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6">
                      <img
                        className="w-100  my-4 "
                        src={gamerizeThreeB}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        className="w-100 my-4  "
                        src={gamerizeThreeC}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        className="w-100 my-4  "
                        src={gamerizeThreeD}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      As student feedback started coming in, we quickly identified which mini-games
                      needed enhancement, how the game economy and enemy ‘strengths’ needed to be
                      tweaked, and what kind of glitches might arise at the teacher and student ends
                      during the live use of the system.
                    </p>
                    <br />
                    <p className="description">
                      The team spent the next few months debugging individual user accounts to make
                      the system more robust. We constantly added new features such as dashboards
                      with precise analytics and live people on the map scene to make the gameplay
                      more engaging and educationally rigorous. The more subtle elements such as
                      lighting and water effects were also added in tandem to improve the user
                      experience.
                    </p>
                    <br />
                    <p className="description">
                      The whole process was iterative as the team identified problems and figured
                      out solutions as the project went on. Constant Slack communication and weekly
                      calls with the client’s team afforded us quick feedback on the shared
                      mechanics and visuals. On our end, the team comprising artists, designers,
                      engineers and testers employed best management practices to ensure all the
                      workflows were documented and daily tasks perfectly aligned.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100  " src={gamerizeFour} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      The Gamerize Dictionary mobile app was launched in 2021 and could be accessed
                      on both iOS and Android. We developed four types of language learning
                      mini-games – card slashing, archery, bridge crossing, and RPG battles with
                      monsters – within the app. Completing these quests let students use
                      accumulated resources to buy buildings in order to grow their kingdoms.
                    </p>
                    <br />
                    <p className="description">
                      The gamified experience included four ways of learning a language – both
                      spelling and speaking words as well as sentences. The whole approach allowed
                      children to use rewards in a personalized way and see a visualized
                      representation of their cumulative efforts over a period of time. The aim was
                      to help learners develop an awareness that their small regular efforts result
                      in bigger gains over time. The game also released review monsters on the
                      kingdom to motivate children to engage in review battles to protect buildings
                      as well as their learning gains.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="image-description text-center mb-2 col-12">In-Game Screens</div>
                    <div className="col-lg-6">
                      <img className="w-100 my-4  " src={gamerizeFiveA} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6">
                      <img className="w-100 my-4  " src={gamerizeFiveB} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6">
                      <img className="w-100 my-4  " src={gamerizeFiveC} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6">
                      <img className="w-100 my-4  " src={gamerizeFiveD} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Gamerize Dictionary managed to garner early traction. According to the latest
                      data, the app had over 2000 organic users, 500 daily sessions, and 10
                      check-ins on average per day. The engagement metrics were also promising, with
                      students spending on average 20 minutes daily learning through the app.
                    </p>
                    <br />
                    <p className="description">
                      Tintash continues to work on scalability and enhancements as user feedback
                      pours in, and shares in the AAS Press’ vision of making the game bigger and
                      taking it beyond the Japanese market.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Gamerize;
